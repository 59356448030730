<template>
  <v-container class="pa-0">
    <v-row class="no-gutters elevation-0">
      <v-col cols="auto" class="flex-grow-1 flex-shrink-0">
        <v-responsive class="custom-chat-height-wrapper">
          <v-card class="d-flex flex-column fill-height">
            <v-card-title class="chat-header">
              <label>
                <span>Inter Change Code</span>
              </label>
            </v-card-title>
            <v-card-text
              class="pa-0 flex-grow-1 overflow-y-auto custom-chat-height"
            >
              <div class="pa-10">
                <template>
                  <v-form v-model="valid">
                    <v-card-title class="pa-0 pl-3 pr-3">
                      <v-radio-group v-model="row" row class="custom-pdm">
                        <v-radio label="My Invertory" value="radio-1"></v-radio>
                        <v-radio
                          label="Network Invertory"
                          value="radio-2"
                        ></v-radio>
                        <v-radio label="" value="radio-3"></v-radio>
                        <v-text-field label="Type Zip Code" solo></v-text-field>
                      </v-radio-group>
                    </v-card-title>
                    <v-container>
                      <v-row>
                        <v-col cols="12" md="12">
                          <v-text-field
                            v-model="first"
                            label="Part Name"
                            solo
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="first"
                            label="Make"
                            solo
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-select
                            :items="items"
                            label="Model"
                            solo
                          ></v-select>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-select :items="items" label="Year" solo></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="first"
                            label="Trim Description"
                            solo
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="12">
                          <v-btn
                            large
                            color="green darkan-4 green-gradient white--text"
                            class="text-capitalize btn-submit"
                            >Submit</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </template>
              </div>
            </v-card-text>
          </v-card>
        </v-responsive>
      </v-col>
    </v-row></v-container
  >
</template>
             
<script>
export default {
  data() {
    return {};
  },
};
</script>